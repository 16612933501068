export const initialSnackbarState = {
  open: false,
  message: '',
  severity: 'info' // 'success' | 'error' | 'warning' | 'info'
}

export const snackbarReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_SNACKBAR':
      return {
        open: true,
        message: action.payload.message,
        severity: action.payload.severity
      }
    case 'HIDE_SNACKBAR':
      return {
        ...state,
        open: false
      }
    default:
      return state
  }
}

import React from 'react'
import { hot } from 'react-hot-loader/root'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Grid from '@material-ui/core/Grid'
import CssBaseline from '@material-ui/core/CssBaseline'
import routes from './routes'
import { Provider as EmployeeListProvider } from './state/employeeList/context'
import { Provider as CVProvider } from './state/cv/context'
import { Provider as UIProvider } from './state/ui/context'
import { Provider as SettingsProvider } from './state/settings/context'
import Settings from './containers/Settings'
import Theme from './containers/Theme'
import { SnackbarProvider } from './state/snackbar/context'

const App = () => (
  <SettingsProvider>
    <Settings>
      <Theme>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <UIProvider>
            <SnackbarProvider>
              <EmployeeListProvider>
                <CVProvider>
                  <Grid container spacing={0} justifyContent="center">
                    <Grid item xs={12} md={9}>
                      {routes}
                    </Grid>
                  </Grid>
                </CVProvider>
              </EmployeeListProvider>
            </SnackbarProvider>
          </UIProvider>
        </MuiPickersUtilsProvider>
      </Theme>
    </Settings>
  </SettingsProvider>
)
export default hot(App)

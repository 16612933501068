import React from 'react'
import { act } from 'react-dom/test-utils'

const SET_LOADER = 'ui/SET_LOADER'

function useUIReducer() {
  const [ui, dispatch] = React.useReducer(
    (state, { type, payload }) => {
      switch (type) {
        case SET_LOADER:
          return {
            ...state,
            isLoading: payload
          }
        default:
          return state
      }
    },
    {
      isLoading: false
    }
  )
  const setLoader = payload =>
    act(() => {
      dispatch({
        type: SET_LOADER,
        payload
      })
    })

  return [ui, { setLoader }]
}

export default useUIReducer

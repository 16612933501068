import React, { createContext, useContext, useReducer } from 'react'
import { Slide, Snackbar } from '@material-ui/core'
import { node } from 'prop-types'
import { Alert } from '@material-ui/lab'
import { snackbarReducer, initialSnackbarState } from './snackbarReducer'

const SnackbarContext = createContext()

export const useSnackbar = () => useContext(SnackbarContext)

export const SnackbarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(snackbarReducer, initialSnackbarState)

  const showSnackbar = (message, severity = 'info') => {
    dispatch({
      type: 'SHOW_SNACKBAR',
      payload: { message, severity }
    })
  }
  const hideSnackbar = () => {
    dispatch({ type: 'HIDE_SNACKBAR' })
  }

  // Return the provider and render the Snackbar
  return (
    <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
      {children}
      <Snackbar
        open={state.open}
        autoHideDuration={4000}
        onClose={hideSnackbar}
        TransitionComponent={props => <Slide {...props} direction="up" />}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={hideSnackbar} severity={state.severity} variant="filled">
          {state.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}
SnackbarProvider.propTypes = {
  children: node.isRequired
}

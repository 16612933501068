import showdown from 'showdown'
import axios from 'axios'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { downloadFile } from './client'

const markdownConverter = new showdown.Converter()

export function debounced(fn, delay) {
  let timerId
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(() => {
      fn(...args)
      timerId = null
    }, delay)
  }
}

export function sanitizeStackTags(stack = '') {
  return stack
    ? stack
        .split(',')
        .map(tag => tag.trim())
        .filter(tag => !!tag)
        .join(', ')
    : ''
}

function sanitizeCVData(key, keyData) {
  if (key === 'experience') {
    return keyData.map(({ stack, ...others }) => ({
      ...others,
      stack: sanitizeStackTags(stack)
    }))
  }
  return keyData
}

export function addModifiedDataToCV(data, key, keyData, index) {
  return {
    ...data.versions[index],
    [key]: sanitizeCVData(key, keyData)
  }
}

export function convertMarkdownToHtml(markdownText) {
  return markdownConverter.makeHtml(markdownText)
}

export function requestAndDownloadFile(token, downloadUrl) {
  return axios.request(downloadFile(token, downloadUrl)).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${response.headers['x-file-name']}.pdf`)
    document.body.appendChild(link)
    link.click()
    return true
  })
}

/** parseBlobResponse
 *
 * @param {Blob} data
 */
export function parseBlobResponse(data) {
  const fileReader = new FileReader()
  fileReader.readAsText(data)

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort()
      reject(new Error('Problem parsing file'))
    }

    fileReader.onload = () => {
      resolve(JSON.parse(fileReader.result))
    }
  })
}

/** GetBase64
 *
 * @param {*} file
 */
export function getBase64(file) {
  const reader = new FileReader()
  reader.readAsDataURL(file)

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = error => {
      reject(error)
    }
  })
}

export const getCommaSeparated = values => values.filter(val => val).join(', ')

export const sortCVVersionsByDate = versions => {
  // To  not lose the index and make it retro compatible we need to assign
  // the current index in the list to the object so in that way
  // we can have real index, so it doesn't matter in what position is in the list
  // because we will have the previous index before sorting.
  if (!versions) return ''
  return [
    ...versions
      .map((item, index) => ({
        ...item,
        index
      }))
      .sort((a, b) => {
        if (a.isMaster && !b.isMaster) return -1
        if (!a.isMaster && b.isMaster) return 1

        // Sort by versionDate if 'Master' is not involved
        if (a.versionDate && b.versionDate) {
          return new Date(b.versionDate) - new Date(a.versionDate)
        }
        return 1
      })
  ]
}

export const getVersionFullName = objVersion => {
  if (!objVersion) return ''

  const { index, isMaster, client, jobPosition, versionDate } = objVersion

  if (jobPosition) {
    const clientValue = client ? `${client} - ` : ''
    const formattedVersionDate = versionDate
      ? `- ${format(parseISO(versionDate), 'dd-MM-yyyy')}`
      : ''
    return `${clientValue}${jobPosition} ${formattedVersionDate}`
  }

  if (isMaster) {
    return 'Master'
  }

  return `Version ${index}`
}

export const getFullLanguageName = language => {
  return language === 'en' || language === undefined ? 'English' : 'Dutch'
}
